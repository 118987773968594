<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t("staff.staff_head") }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("staff.staff_data") }} </v-card-title>

        <v-form ref="form" v-model="formValid">
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-text-field v-model="datas.username" label="username" :rules="formRule.username" outlined dense required
                  readonly disabled />
              </v-col>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-text-field v-model="datas.password" label="password" :rules="formRule.password" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-text-field v-model="datas.name" :label="$t('staff.staff_name')" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-select v-model="datas.role_id" label="role" :rules="[v => !!v || '']" :items="roles" item-text="label"
                  outlined dense required />
              </v-col>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-text-field v-model="datas.line" label="Line" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-text-field v-model="datas.email" :rules="[v => !!v || '']" :label="$t('staff.staff_email')" outlined
                  dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-text-field v-model="datas.phone" :rules="formRule.phone" :label="$t('staff.staff_mobile')" outlined
                  dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'staff' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="editStaff" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('staff.staff_edit') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <!-- <v-col cols="12">
      <v-card>
        <v-card-title> ข้อมูล Staff </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col>
              <v-text-field label="Username" disabled outlined dense />
            </v-col>
            <v-col>
              <v-text-field label="Password" outlined dense type="password" />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'staff' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">ย้อนกลับ</span>
          </v-btn>
          <v-btn color="success" class="px-3">
            <v-icon small>mdi-square-edit-outline</v-icon>
            <span color="primary" class="px-3">สร้าง</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>
import axios from 'axios'
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      formValid: true,
      formRule: {
        username: [
          value => !!value || 'username is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        phone: [
          value => !!value || 'phone is required.',
          value => (!!value && value.length === 10) || 'need equal 10 characters',
        ],
      },
      datas: {
        id: null,
        role_id: null,
        name: '',
        username: null,
        password: null,
        email: null,
        phone: '',
        line: null,
      },
      permissionDialog: false,
      breadcrumbsItems: [
        {
          text: this.$t("staff.staff_list"),
          disabled: false,
          href: '/staff',
        },
        {
          text: this.$t("staff.staff_edit"),
          disabled: true,
          href: '',
        },
      ],
      statusItems: [
        {
          text: 'ผู้ดูแลระบบสูงสุด',
          value: 'superAdmin',
        },
        {
          text: 'ผู้ดูแลระบบ',
          value: 'admin',
        },
        {
          text: 'สมาชิก',
          value: 'member',
        },
      ],
      acceptItems: [
        {
          text: this.$t("yes"),
          value: 'yes',
        },
        {
          text: this.$t("not"),
          value: 'no',
        },
      ],
      loading: false,
      roles: [],
      bankItems: [],
      underUse: [],
      id: this.$route.params.id,
      data: null,
    }
  },
  async created() {
    this.addLogPage()
    try {
      // this.roles = await this.$store.dispatch('getRoleData')
      this.$store.dispatch('getRoleData').then(result => {
        this.roles = result
      })

      // this.data = await this.$store.dispatch('getEmployeeById', this.id)
      this.$store.dispatch('getEmployeeById', this.id).then(result => {
        this.data = result
        this.datas = {
          role_id: this.data.data[0].role.id,
          name: this.data.data[0].name,
          username: this.data.data[0].username,
          password: null,
          email: this.data.data[0].email,
          phone: this.data.data[0].phone,
          line: this.data.data[0].line,
        }
      })
      // this.datas = {
      //   role_id: this.data.data[0].role.id,
      //   name: this.data.data[0].name,
      //   username: this.data.data[0].username,
      //   password: null,
      //   email: this.data.data[0].email,
      //   phone: this.data.data[0].phone,
      //   line: this.data.data[0].line,
      // }
    } catch (e) {
      if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        this.$swal.fire({
          icon: 'error',
          title: e.message,
        })
      }
    }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'พนักงาน',
            url: window.location.href,
            detail: 'แก้ไขพนักงาน',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    linkTo(name) {
      const id = this.$route.params
      this.$router.push({ name: name, params: { id } })
    },
    async editStaff() {
      this.loading = true
      if (this.$refs.form.validate()) {
        let params = {
          id: parseInt(this.id),
          role_id: this.datas.role_id,
          name: this.datas.name,
          password: this.datas.password,
          email: this.datas.email,
          phone: this.datas.phone,
          line: this.datas.line,
        }
        try {
          await this.$store.dispatch('updateEmployee', params)
          this.$router.push({ name: 'staff' })
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      } else {
      }
      this.loading = false
    },
  },
}
</script>
